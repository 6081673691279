
import TableContent from "@/components/common/TableContent.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { TableContent }
})
export default class Table extends Vue {
  @Prop(Object)
  tableHeaderData!: any;

  @Prop(Array)
  tableHeaderActions!: any;

  @Prop({ default: true })
  isShowActions!: boolean;

  @Prop(Array)
  items!: any;

  @Prop(Array)
  headers!: any;

  @Prop(Number)
  page!: number;

  @Prop({ default: false })
  hideDefaultFooter!: boolean;

  @Prop({ default: 50 })
  perPage!: number;

  @Prop(Object)
  pagination!: { total: number; to: number; from: number };

  @Prop(String)
  itemsName!: string;

  @Prop({ default: true })
  isShowTableHeader!: boolean;

  @Prop({ default: true })
  isShowSelect!: boolean;

  @Prop({ default: true })
  isSingleSelect!: boolean;
}
