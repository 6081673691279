
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

const namespace = "table";

@Component
export default class TableContent extends Vue {
  selected = [];
  @Prop(Array)
  items!: any;

  @Prop(Array)
  headers!: any;

  @Prop({ default: true })
  isSingleSelect!: boolean;

  @Prop({ default: false })
  hideDefaultFooter!: boolean;

  @Prop(Number)
  page!: number;

  @Prop({ default: 10 })
  perPage!: number;

  @Prop({
    default: () => ({
      total: 15,
      to: 10,
      from: 1
    })
  })
  pagination!: { total: number; to: number; from: number };

  @Prop({ default: "items" })
  itemsName!: string;

  @Prop({ default: true })
  isShowSelect!: boolean;

  @Prop({ default: "id" })
  itemKey!: string;

  @Action("selectItem", { namespace })
  selectItem: any;

  @Action("refreshItem", { namespace })
  refreshItem: any;

  @Action("multiSelectItems", { namespace })
  multiSelectItems: any;

  @Action("refreshArrayOfItems", { namespace })
  refreshArrayOfItems: any;

  @Action("deleteItemFromArray", { namespace })
  deleteItemFromArray: any;

  destroyed() {
    this.refreshItem();
    this.refreshArrayOfItems();
  }

  selectHandler(data: any): void {
    if (this.isSingleSelect) {
      this.singleSelect(data);
    } else {
      this.multiSelect(data);
    }
  }
  singleSelect(data: any): void {
    if (data.value) {
      this.selectItem(data.item);
    } else {
      this.refreshItem();
    }
  }
  multiSelect(data: any): void {
    if (data.value) {
      this.multiSelectItems(data.item);
    } else {
      this.deleteItemFromArray(data.item);
    }
  }
}
