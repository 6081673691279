
import { Companies, Delegates } from "@/api";
import { Delegate, DelegatesWithPagination } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import Table from "@/components/common/Table.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { Table, PageTitle }
})
export default class DelegatesListPage extends Vue {
  buttonsArray = [
    {
      text: "Create",
      icon: "",
      color: "",
      action: "create"
    }
  ];
  headersTitle = [{ icon: "mdi-account-multiple", header: "All Delegates" }];
  filters = {
    company: null,
    name: "",
    id: "",
    active: 1
  };
  actions = [
    {
      title: "View",
      action: "view"
    },
    {
      title: "Edit",
      action: "edit"
    },
    {
      title: "Delete",
      action: "delete"
    }
  ];
  headers = [
    {
      text: "Company",
      value: "company"
    },
    {
      text: "Delegate",
      value: "name"
    },
    {
      text: "Passport / ID",
      value: "passport"
    },
    {
      text: "Phone number",
      value: "cell_phone"
    },
    {
      text: "Email Verification Status",
      value: "emailStatus",
      sortable: false,
      align: "center",
      width: "180px"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "250px"
    }
  ];
  delegates: Delegate[] = [];
  page = 1;
  limit = 10;
  options = {};
  companies = [];
  delegatesWithPagination: DelegatesWithPagination = {
    meta: {
      perPage: this.limit
    }
  };
  gettingStatus: string | number | null = null;
  sendingEmail: string | number | null = null;

  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    await this.loadDelegatesData(val.page, this.filters);
  }
  @Watch("filters", { deep: true })
  async onChangeFilters() {
    if (this.filters.company === undefined) {
      this.filters.company = null;
    }
    await this.loadDelegatesData(this.page, this.filters);
  }
  async loadDelegatesData(
    page = 1,
    filters: {
      company: number | null;
      name: string;
      id: string;
      active: number | string;
    } = {
      company: null,
      name: "",
      id: "",
      active: 1
    }
  ) {
    try {
      const delegatesWithPagination = await Delegates.list(
        page,
        filters,
        this.limit
      );
      this.delegates = delegatesWithPagination.data
        ? delegatesWithPagination.data
        : [];
      this.delegatesWithPagination = delegatesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async created() {
    try {
      this.companies = await Companies.listWithoutPagination();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  onClearFilters() {
    this.filters = {
      company: null,
      name: "",
      id: "",
      active: 1
    };
  }
  async onView(id: number) {
    await this.$router.push({
      name: "DelegatesView",
      params: { id: id.toString() }
    });
  }
  async onCreate() {
    await this.$router.push({ name: "DelegatesCreate" });
  }
  async onEdit(id: number) {
    await this.$router.push({
      name: "DelegatesEdit",
      params: { id: id.toString() }
    });
  }
  async onDelete(id: number) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Delegates.remove(id.toString());
        await this.loadDelegatesData(this.page, this.filters);
        this.$success("<strong>Success!</strong> Delegate has been deleted!");
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      }
    }
  }
  async getStatus(delegate: Delegate) {
    const { id } = delegate;
    if (id) {
      this.gettingStatus = id;
      try {
        const { status } = await Delegates.getVaultEmailStatus(id);

        delegate.emailStatus = status;
      } catch (e) {
        const err = e as any;
        this.$error(
          err?.errors || { err: err.result?.message || "Cant get status" }
        );
      } finally {
        this.gettingStatus = null;
      }
    }
  }
  async resentEmail(id: string) {
    if (!id) return;
    try {
      this.sendingEmail = id;
      await Delegates.resendVaultVerificationEmail(id);
      this.$success("Verification email sent successfully");
    } catch (e) {
      const err = e as any;
      this.$error(
        err?.errors || { err: err.result?.message || "Cant send email" }
      );
    } finally {
      this.sendingEmail = null;
    }
  }
}
